import { Injectable } from '@angular/core';
import { baseURL, timeoutTime } from '../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/timeout';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  baseUrl = baseURL;
  constructor(private httpClient: HttpClient) {
  }


  getitembymanager(dataTablesParameters, id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `item/list_by_realestate_manager/${id}`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getitembymanager')));
  }
  getitemspagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `item/pagination`, dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getitemspagination')));
  }


  deleteitemtype(id): Observable<any> {
    return this.httpClient.delete(this.baseUrl + `item/admin/${id}`)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error deleteitemtype')));
  }

  createitem(citydata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `item/admin`, citydata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createitemtype')));
  }

  updateitem(id, updatedata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `item/admin/${id}`, updatedata, { headers } )
    .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error updateitemtype')));
  }

  getbyiditem(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.get(this.baseUrl + `item/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyiditemtype')));
  }
  getallitemtype(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `item/admin/list`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getallitemtype')));
  }

  changeitemStatus(id, statusdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `item/admin/change_status/${id}`, statusdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changeitemStatus')));
  }

}
