import { Injectable } from '@angular/core';
import { baseURL, timeoutTime } from '../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/timeout';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  baseUrl = baseURL;
  constructor(private httpClient: HttpClient) {
  }

  getamanagerspagination(dataTablesParameters): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + 'realestatemanager/pagination', dataTablesParameters, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getamanagerspagination')));
  }

  changemanagersStatus(id, statusdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/change_status/${id}`, statusdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error changemanagersStatus')));
  }

  createmanager(managerdata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/add`, managerdata, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error createmanager')));
  }

  updatemanager(id, updatedata): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(this.baseUrl + `realestatemanager/${id}`, updatedata, { headers } )
    .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error updatemanager')));
  }

  getbyidmanager(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.get(this.baseUrl + `realestatemanager/${id}`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getbyidmanager')));
  }

  deletemanager(id): Observable<any> {
    return this.httpClient.delete(this.baseUrl + `realestatemanager/${id}`)
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error deletemanager')));
  }

  getallmanagers(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(this.baseUrl + `realestatemanager/list`, { headers })
      .timeoutWith(timeoutTime, Observable.throw(new Error('Timeout error getallmanagers')));
  }
}
